import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProposedResolutionProps from '../../types/ProposedResolutionProps';

class ProposerField extends React.Component {
  constructor(props) {
    super(props);

    this.handleProposerInputChange = this.handleProposerInputChange.bind(this);
    this.handleProposerChange = this.handleProposerChange.bind(this);
    this.handleProposerNameChange = this.handleProposerNameChange.bind(this);
  }

  handleProposerInputChange(item) {
    this.props.fetchProposers(item);
  }

  handleProposerChange(item) {
    this.props.setProposedResolutionProposer(item);
  }

  handleProposerNameChange(item) {
    const { proposer } = this.props.proposed_resolution;
    const newProposer = { 
      value: proposer.actorId, 
      label: item.target.value,
      data: { actorType: proposer.actorType }
    };

    this.props.setProposedResolutionProposer(newProposer);
  }

  render() {
    const proposers = this.props.proposers.map((proposer) => ({
      value: proposer.actorId, label: proposer.actorName, data: { actorType: proposer.actorType }
    }));
    
    let selectedValue = null;
    
    if (this.props.proposed_resolution.proposer.actorId) {
      selectedValue = {
        value: this.props.proposed_resolution.proposer.actorId,
        label: this.props.proposed_resolution.proposer.actorName,
        data: {
          actorType: this.props.proposed_resolution.proposer.actorType
        }
      };
    }
    const selectClass = this.props.selectProposer ? '' : 'hide';
    const inputClass = this.props.selectProposer ? 'hide' : 'form-control';
    
    return (
      <div className="form-group">
        <label className="required" htmlFor="proposed_resolution_proposer">Proposer</label>
        <Select 
          className={selectClass}
          value={selectedValue}
          options={proposers}
          name="proposed_resolution[proposer_actor_id]"
          placeholder="Please select..."
          onInputChange={this.handleProposerInputChange}
          onChange={this.handleProposerChange}
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              text: 'orangered',
              primary25: '#ddd',
              primary: '#3e8ede',
            },
          })}
        />
        <input
          className={inputClass}
          type="text"
          name="proposed_resolution[proposer_name]"
          value={this.props.proposed_resolution.proposer.actorName}
          onChange={this.handleProposerNameChange}
        />
        <input
          type="hidden"
          name="proposed_resolution[proposer_type]"
          value={this.props.proposed_resolution.proposer.actorType}
        />
        <input
          type="hidden"
          name="proposed_resolution[proposer_id]"
          value={this.props.proposed_resolution.proposer.id}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  proposed_resolution: state.proposedResolutionForm.proposed_resolution,
  proposers: state.proposedResolutionForm.proposers,
  selectProposer: state.proposedResolutionForm.selectProposer,
});

const mapDispatch = (dispatch) => ({
  fetchProposers: (term) => dispatch.proposedResolutionForm.fetchProposers(term),
  setProposedResolutionProposer: (proposer) => 
    dispatch.proposedResolutionForm.setProposedResolutionProposer(proposer),
});

ProposerField.propTypes = {
  proposed_resolution: ProposedResolutionProps.isRequired,
  fetchProposers: PropTypes.func.isRequired,
  setProposedResolutionProposer: PropTypes.func.isRequired,
  proposers: PropTypes.instanceOf(Array).isRequired,
  selectProposer: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(ProposerField);
