import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilingRequirementsStatusIndicator from './FilingRequirementsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';

class ResolutionNumberForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resolutionNumberInputRequired: false,
      resolutionNumberInputDisabled: true,
      resolutionNumber: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAutoGenerateChange = this.handleAutoGenerateChange.bind(this);
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const data = this.state.resolutionNumber;
    const autogen = this.state.resolutionNumberInputDisabled;

    this.props.saveResolutionNumber({
      resolution: {
        resolution_type_id: this.props.resolution.resolutionType.id,
        resolution_number: data,
        auto_generate: autogen
      }
    });
  }

  handleInputChange(event) {
    const resolutionNumber = event.target.value;

    this.setState(() => ({
      resolutionNumber
    }));
  }

  handleAutoGenerateChange(event) {
    const checkedValue = event.target.checked;

    this.setState(() => ({
      resolutionNumberInputRequired: !checkedValue,
      resolutionNumberInputDisabled: checkedValue,
      resolutionNumber: null
    }));
  }

  render() {
    const currentResolutionTypePrefix = this.props.resolution.resolutionType.prefix;
    let resolutionNumberClass = 'hide';
    let formClass = '';

    if (this.props.resolution.filingRequirementStatusResolutionNumber === 'complete') {
      formClass = 'hide';
      resolutionNumberClass = '';
    }

    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center filing-requirement__spinner-column">
          <FilingRequirementsStatusIndicator
            status={this.props.resolution.filingRequirementStatusResolutionNumber}
          />
        </td>
        <td className="table--fixed-column">
          <strong>Resolution number</strong>
        </td>
        <td>
          <form className={formClass} onSubmit={this.handleFormSubmit} id="filing-requirement__resolution-number-form">
            <input type="text" defaultValue={this.props.resolution.resolutionType.id} name="resolution[resolution_type_id]" hidden />
            <div className="form-group">
              <div className="flex flex--align-center">
                <div className="resolution-number-group">
                  <span>{currentResolutionTypePrefix}</span>
                </div>
                <div className="resolution-number-group">
                  <input
                    type="text"
                    className="form-control form-control--small"
                    name="resolution[resolution_number]"
                    required={this.state.resolutionNumberInputRequired}
                    disabled={this.state.resolutionNumberInputDisabled}
                    placeholder="3 digits only"
                    pattern="\d{3}"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="resolution-number-group" />
                <div className="">
                  <label className="control checkbox">
                    <input type="checkbox" name="resolution[auto_generate]" defaultChecked onChange={this.handleAutoGenerateChange} />
                    <span className="control-indicator" />
                    Generate automatically
                  </label>
                </div>
              </div>
              <p className="help-block">
                Last resolution was&nbsp;
                {this.props.resolution.lastResolutionNumber}
                .
              </p>
            </div>

            <input
              type="submit"
              className="modal__open--resolution-number btn btn--small btn--primary"
              value="Assign"
            />
          </form>

          <span className={resolutionNumberClass}>{this.props.resolution.resolutionNumber}</span>
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  resolution: state.filingRequirements.resolution,
});

const mapDispatch = ({ filingRequirements: { saveResolutionNumber } }) => ({
  saveResolutionNumber: (data) => saveResolutionNumber(data),
});

ResolutionNumberForm.propTypes = {
  resolution: ResolutionProps.isRequired,
  saveResolutionNumber: PropTypes.func.isRequired
};

export default connect(mapState, mapDispatch)(ResolutionNumberForm);
