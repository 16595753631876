import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ProposedResolutionProps from '../../types/ProposedResolutionProps';
import ProposedResolutionFormFields from './ProposedResolutionFormFields';

function ProposedResolutionFormFieldsContainer(props) {
  return (
    <Provider store={window.store}>
      { props && (
      <ProposedResolutionFormFields 
        proposed_resolution={props.proposed_resolution}
        proposerBaseUrl={props.proposer_base_url}
      />
)}
    </Provider>
  );
}

ProposedResolutionFormFieldsContainer.propTypes = {
  proposed_resolution: ProposedResolutionProps.isRequired,
  proposer_base_url: PropTypes.string.isRequired,
};

export default ProposedResolutionFormFieldsContainer;
