import React from 'react';
import PropTypes from 'prop-types';

function SuccessAlert(props) {
  return (
    <div className="alert alert--success" role="alert">
      <p>{props.message}</p>
    </div>
  );
}

SuccessAlert.propTypes = {
  message: PropTypes.string.isRequired
};

export default SuccessAlert;
