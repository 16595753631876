import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProposedResolutionProps from '../../types/ProposedResolutionProps';
 
class ResolutionTypeField extends React.Component {
  constructor(props) {
    super(props);
    this.handleResolutionTypeChange = this.handleResolutionTypeChange.bind(this);
  }

  handleResolutionTypeChange(item) {
    this.props.changeResolutionType(item);
  }

  render() {
    const resolutionTypes = this.props.resolutionTypes.map((type) => ({
      value: type.id, label: type.title, data: { code: type.code, prefix: type.prefix }
    }));
    let selectedValue = null;
    if (this.props.proposed_resolution.resolutionType.id) {
      selectedValue = {
        value: this.props.proposed_resolution.resolutionType.id,
        label: this.props.proposed_resolution.resolutionType.title,
        data: {
          code: this.props.proposed_resolution.resolutionType.code,
          prefix: this.props.proposed_resolution.resolutionType.prefix
        }
      };
    }

    return (
      <div className="form-group">
        <label className="required" htmlFor="resolution_type">Type</label>
        <Select
          name="proposed_resolution[resolution_type_id]"
          options={resolutionTypes}
          isSearchable
          placeholder="Please select..."
          value={selectedValue}
          onChange={this.handleResolutionTypeChange}
          isDisabled={this.props.proposed_resolution.id}
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              text: 'orangered',
              primary25: '#ddd',
              primary: '#3e8ede',
            },
          })}
        />
        <input
          type="hidden"
          name="proposed_resolution[code]"
          value={this.props.proposed_resolution.resolutionType.code}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  proposed_resolution: state.proposedResolutionForm.proposed_resolution,
  resolutionTypes: state.proposedResolutionForm.resolutionTypes,
});

const mapDispatch = ({ proposedResolutionForm: { changeResolutionType } }) => ({
  changeResolutionType: (resolutionType) => changeResolutionType(resolutionType),
});

ResolutionTypeField.propTypes = {
  proposed_resolution: ProposedResolutionProps.isRequired,
  changeResolutionType: PropTypes.func.isRequired,
  resolutionTypes: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapState, mapDispatch)(ResolutionTypeField);
