import React from 'react';
import { Provider } from 'react-redux';
import ArchiveResolutionsForm from './ArchiveResolutionsForm';
import ResolutionProps from '../../types/ResolutionProps';

function ArchiveResolutionsContainer(props) {
  return (
    <Provider store={window.store}>
      <ArchiveResolutionsForm resolution={props.resolution} />
    </Provider>
  );
}

ArchiveResolutionsContainer.propTypes = {
  resolution: ResolutionProps.isRequired,
};

export default ArchiveResolutionsContainer;
