import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilingRequirementsStatusIndicator from './FilingRequirementsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';

class LegislativeCommitteeForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleLegislativeCommitteeChange = this.handleLegislativeCommitteeChange.bind(this);
  }

  handleLegislativeCommitteeChange(item) {
    this.props.saveResolutionAssignedCommittee(item);
  }

  render() {
    const nullValue = [{ value: null, label: 'None' }];
    const committees = nullValue.concat(
        this.props.committees.map((committee) => ({ value: committee.id, label: committee.name }))
    );

    let selectedValue = null;
    if (this.props.resolution.assignedLegislativeCommitteeId) {
      selectedValue = {
        value: this.props.resolution.assignedLegislativeCommitteeId,
        label: this.props.resolution.assignedLegislativeCommitteeName
      };
    }

    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center">
          <FilingRequirementsStatusIndicator
            status={this.props.resolution.filingRequirementStatusAssignedCommittee}
          />
        </td>

        <td className="table--fixed-column"><strong>Assigned Committee</strong></td>

        <td>
          <Select
            options={committees}
            isSearchable
            placeholder="Please select..."
            value={selectedValue}
            onChange={this.handleLegislativeCommitteeChange}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                text: 'orangered',
                primary25: '#ddd',
                primary: '#3e8ede',
              },
            })}
          />
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  committees: state.filingRequirements.committees,
  resolution: state.filingRequirements.resolution,
});

const mapDispatch = ({ filingRequirements: { saveResolutionAssignedCommittee } }) => ({
  saveResolutionAssignedCommittee: (assignedCommittee) => 
    saveResolutionAssignedCommittee(assignedCommittee),
});

LegislativeCommitteeForm.propTypes = {
  committees: PropTypes.instanceOf(Array).isRequired,
  resolution: ResolutionProps.isRequired,
  saveResolutionAssignedCommittee: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(LegislativeCommitteeForm);
