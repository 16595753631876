import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProposedResolutionProps from '../../types/ProposedResolutionProps';
import ErrorAlert from '../ErrorAlert';
import ResolutionTypeField from './ResolutionTypeField';
import ProposerField from './ProposerField';
import EndorsersField from './EndorsersField';
import SupportersField from './SupportersField';

class ProposedResolutionFormFields extends React.Component {
  componentDidMount() {
    this.props.hydrateProposedResolutionForm(this.props.proposed_resolution);
    this.props.setProposerBaseUrl(this.props.proposerBaseUrl);
  }

  render() {
    return ( 
      <div>
        { this.props.showErrors && <ErrorAlert message={this.props.errorMessage} /> }
        <ResolutionTypeField />
        <ProposerField />
        <EndorsersField />
        <SupportersField />

      </div>
    );
  }
}
 
const mapState = (state) => ({
  errorMessage: state.proposedResolutionForm.errorMessage,
  showErrors: state.proposedResolutionForm.showErrors,
});

const mapDispatch = (dispatch) => ({
  /* eslint-disable camelcase */
  hydrateProposedResolutionForm: (proposed_resolution) => 
    dispatch.proposedResolutionForm.hydrateProposedResolutionForm(proposed_resolution),
  setProposerBaseUrl: (baseUrl) => dispatch.proposedResolutionForm.setProposerBaseUrl(baseUrl),
});

ProposedResolutionFormFields.propTypes = {
  proposed_resolution: ProposedResolutionProps.isRequired,
  proposerBaseUrl: PropTypes.string.isRequired,
  hydrateProposedResolutionForm: PropTypes.func.isRequired,
  setProposerBaseUrl: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(ProposedResolutionFormFields);
