import { init } from '@rematch/core';
import FilingRequirementsModel from '../models/FilingRequirementsModel';
import AccessTokensModel from '../models/AccessTokensModel';
import PrefileResolutionFormModel from '../models/PrefileResolutionFormModel';
import ProposedResolutionFormModel from '../models/ProposedResolutionFormModel';
import ArchiveResolutionsModel from '../models/ArchiveResolutionsModel';

const store = init({
  models: {
    filingRequirements: FilingRequirementsModel,
    accessTokensModel: AccessTokensModel,
    prefileResolutionForm: PrefileResolutionFormModel,
    proposedResolutionForm: ProposedResolutionFormModel,
    archiveResolutions: ArchiveResolutionsModel
  }
});

export default store;
