import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoPopup from '../InfoPopup';
import FilingRequirementsStatusIndicator from './FilingRequirementsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';

class HouseOfInitialActionForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleHouseOfInitialActionChange = this.handleHouseOfInitialActionChange.bind(this);
  }

  handleHouseOfInitialActionChange(item) {
    this.props.saveResolutionHouseOfInitialAction(item.target.value * 1);
  }

  render() {
    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center">
          <FilingRequirementsStatusIndicator
            status={this.props.resolution.filingRequirementStatusHouseOfInitialAction}
          />
        </td>

        <td className="table--fixed-column">
          <strong>House of Initial Action &nbsp;</strong>
          <span className="grey-medium h5 hia-tooltip">
            <InfoPopup
              content="The House (of a one house resolution), or house of initial action (of a two house resolution)"
            />
          </span>
        </td>

        <td>
          <div className="controls-stacked space-below--none">
            { this.props.houses.map((house) => (
              <label className="control radio" key={house.id}>
                <input
                  id={house.code}
                  type="radio"
                  value={house.id}
                  checked={this.props.resolution.houseOfInitialAction === house.id}
                  onChange={this.handleHouseOfInitialActionChange}
                />
                <span className="control-indicator" />
                {house.short_name}
              </label>
              )) }
          </div>
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  resolution: state.filingRequirements.resolution,
  houses: state.filingRequirements.houses,
});

const mapDispatch = ({ filingRequirements: { saveResolutionHouseOfInitialAction } }) => ({
  saveResolutionHouseOfInitialAction: (houseOfInitialAction) =>
    saveResolutionHouseOfInitialAction(houseOfInitialAction),
});

HouseOfInitialActionForm.propTypes = {
  resolution: ResolutionProps.isRequired,
  saveResolutionHouseOfInitialAction: PropTypes.func.isRequired,
  houses: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapState, mapDispatch)(HouseOfInitialActionForm);
