/* eslint-env jquery */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */

export default function detectHouseMessageTextChange() {
  $(window).on('load', () => {
    if (window.location.pathname.match(/house_messages/) && $('#restore-text-button').length) {
      CKEDITOR.on('instanceReady', () => {
        const StartingText = $('iframe').contents().find('body').text();
        
        textChangeListener();

        function textChangeListener() {
          const textWindow = $('iframe')[0].contentWindow.document;
          $(textWindow).off('keyup paste change', getNewText);
          $(textWindow).on('keyup paste change', getNewText);
        }

        function getNewText() {
          const currentText = $('iframe').contents().find('body').text();
          compareText(currentText);
        }

        function compareText(currentText) {
          hideWarning();
          hideAlert();
          if (StartingText !== currentText) {
            showWarning();
          }
        }
        
        function hideWarning() {
          $('.alert--danger').addClass('hide');
          $('.alert--danger').removeClass('show');
        }

        function hideAlert() {
          $('.alert--success').addClass('hide');
          $('.alert--success').removeClass('show');
        }

        function showWarning() {
          $('.alert--danger').removeClass('hide');
          $('.alert--danger').addClass('show');
        }

        $('#restore-text-button').on('click', () => {
            hideWarning();
            hideAlert();
            textChangeListener();
        });
      });
    }
  });
}
