import axios from 'axios';

// eslint-disable-next-line max-len
const fileResolution = function fileResolution(dispatch, accessToken, currentUserId, resolutionId, filingUrl) {
  axios.patch(filingUrl, { params: { access_token_lpo: accessToken, creator_id: currentUserId } })
    .then((response) => {
      const successMessage = response.data.metadata;

      dispatch.filingRequirements.setSuccessMessage(successMessage);
      dispatch.filingRequirements.setFileResolutionsModalAsClosed();

      window.setTimeout(() => {
          const targetPath = '/prefile_resolutions';
          window.location.href = targetPath;
        }, 200);
    })
    .catch((error) => {
      const errorContext = error.response === undefined
        ? error : JSON.stringify(error.response.data.message);

      dispatch.filingRequirements.setFileResolutionsModalErrors(
        `Error: ${errorContext}`
      );
    });
};

const FilingRequirementsModel = {
  state: {
    committees: [],
    errorMessage: '',
    noticeMessages: [],
    successMessage: '',
    resolution: {
      id: null,
      resolutionType: {
        id: null,
        prefix: null,
        code: null,
      },
      filingStatus: 'prefiled',
      readyForTranslation: false,
      resolutionReportSent: false,
      conflictReportSent: false,
      researchUrl: null,
      lastResolutionNumber: null,
      assignedLegislativeCommitteeId: null,
      assignedLegislativeCommitteeName: null,
      topicId: null,
      topic: null,
      resolutionNumber: null,
      legislativeType: null,
      houseOfInitialAction: null,
      titleWithPrefixedNumber: null,
      filingRequirementStatusResolutionNumber: 'incomplete',
      filingRequirementStatusAssignedCommittee: 'incomplete',
      filingRequirementStatusTopic: 'incomplete',
      filingRequirementStatusLegislativeType: 'incomplete',
      filingRequirementStatusHouseOfInitialAction: 'incomplete',
      filingRequirementStatusResearchUrl: 'incomplete',
      filingRequirementStatusOfficerReports: 'incomplete',
    },
    showErrors: false,
    showSuccessMessages: false,
    showResearchUrlModalErrors: false,
    closeResearchUrlModal: false,
    showFileResolutionsModalErrors: false,
    showFileResolutionsModalNotice: false,
    closeFileResolutionsModal: false,
    filingRequirementsMet: false,
    topics: [],
    houses: [],
  },

  reducers: {
    setReadyForTranslationCheckboxState(state, attribute, value) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          [attribute]: value
        }
      };
    },

    setOfficerReportsCheckboxState(state, attribute, value) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          [attribute]: value
        }
      };
    },

    setResearchUrlAsAttached(state, url) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          researchUrl: url,
        },
        closeResearchUrlModal: true
      };
    },

    resetResearchUrlModalErrors(state) {
      return {
        ...state,
        errorMessage: '',
        showResearchUrlModalErrors: false,
      };
    },

    clearCloseResearchUrlModal(state) {
      return {
        ...state,
        closeResearchUrlModal: false
      };
    },

    setResolutionNumber(state, resolutionNumber) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          resolutionNumber
        }
      };
    },

    setLastResolutionNumber(state, lastResolutionNumber) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          lastResolutionNumber
        }
      };
    },

    setResolutionTopic(state, newTopic) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          topicId: newTopic.value,
          topic: newTopic.label
        }
      };
    },

    setResolutionAssignedLegislativeCommittee(state, assignedLegislativeCommittee) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          assignedLegislativeCommitteeId: assignedLegislativeCommittee.value,
          assignedLegislativeCommitteeName: assignedLegislativeCommittee.label,
        }
      };
    },

    setResolutionLegislativeType(state, legislativeType) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          legislativeType,
        }
      };
    },

    setResolutionHouseOfIntialAction(state, houseOfInitialAction) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          houseOfInitialAction,
        }
      };
    },

    setCommittees(state, committees) { return { ...state, committees }; },
    setTopics(state, topics) { return { ...state, topics }; },
    setHouses(state, houses) { return { ...state, houses }; },

    setPrefilingStatus(state, attribute, loadingStatus) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          [attribute]: loadingStatus
        }
      };
    },

    setResolution(state, resolution) {
      return {
        ...state,
        resolution: {
          id: resolution.id,
          resolutionType: {
            id: resolution.resolution_type.id,
            prefix: resolution.resolution_type_prefix,
            code: resolution.resolution_type_code,
          },
          assignedLegislativeCommitteeId: resolution.assigned_legislative_committee_id,
          assignedLegislativeCommitteeName: resolution.assigned_legislative_committee_name,
          topicId: resolution.topic_id,
          topic: resolution.topic,
          resolutionNumber: resolution.number_with_prefix,
          legislativeType: resolution.legislative_type,
          houseOfInitialAction: resolution.house_of_initial_action_id,
          titleWithPrefixedNumber: resolution.title_with_prefixed_number,
          filingRequirementStatusResolutionNumber:
            resolution.filing_requirement_status_resolution_number,
          filingRequirementStatusTopic: resolution.filing_requirement_status_topic,
          filingRequirementStatusAssignedCommittee:
            resolution.filing_requirement_status_assigned_committee,
          filingRequirementStatusLegislativeType:
            resolution.filing_requirement_status_legislative_type,
          filingRequirementStatusHouseOfInitialAction:
            resolution.filing_requirement_status_house_of_initial_action,
          filingRequirementStatusOfficerReports:
            resolution.filing_requirement_status_officer_reports,
          filingRequirementStatusResearchUrl:
            resolution.filing_requirement_status_research_url,
          readyForTranslation: resolution.checklist.ready_for_translation,
          resolutionReportSent: resolution.checklist.resolution_report_sent,
          conflictReportSent: resolution.checklist.conflict_report_sent,
          researchUrl: resolution.research_url,
        }
      };
    },

    setErrorMessage(state, message) {
      return { ...state, showErrors: true, errorMessage: message };
    },

    setSuccessMessage(state, message) {
      return { ...state, showSuccessMessages: true, successMessage: message };
    },

    setResearchUrlModalErrorMessage(state, message) {
      return { ...state, showResearchUrlModalErrors: true, errorMessage: message };
    },

    setFileResolutionsModalErrors(state, message) {
      return { ...state, showFileResolutionsModalErrors: true, errorMessage: message };
    },

    setFileResolutionsModalNotice(state, messages) {
      return { ...state, showFileResolutionsModalNotice: true, noticeMessages: messages };
    },

    setFilingRequirementsAsMet(state) {
      return { ...state, filingRequirementsMet: true };
    },

    resetFileResolutionsModalNotice(state) {
      return {
        ...state,
        showFileResolutionsModalNotice: false,
      };
    },

    setFileResolutionsModalAsClosed(state) {
      return {
        ...state,
        closeFileResolutionsModal: true,
      };
    }

  },

  effects: (dispatch) => ({
    async saveResolutionTopic(topic, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const topicId = topic.value;

      dispatch.filingRequirements.setResolutionTopic(topic);
      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusTopic', 'loading');

      axios.put(`/api/v1/resolutions/${resolutionId}.json`, {
        access_token_lpo: accessToken,
        resolution: {
          topic_id: topicId
        }
      })
        .then(() => {
          // Delay marking the request as "complete" a short time so the user has a moment to see
          // the task was performed.
          window.setTimeout(() => {
              dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusTopic', 'complete');
            }, 200);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
             + ' Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusTopic', 'error');
        });
    },

    async saveResolutionAssignedCommittee(assignedCommittee, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const assignedCommitteeId = assignedCommittee.value;

      dispatch.filingRequirements.setResolutionAssignedLegislativeCommittee(assignedCommittee);
      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusAssignedCommittee', 'loading');

      axios.put(`/api/v1/resolutions/${resolutionId}.json`, {
        access_token_lpo: accessToken,
        resolution: {
          assigned_legislative_committee_id: assignedCommitteeId
        }
      })
        .then(() => {
          // Delay marking the request as "complete" a short time so the user has a moment to see
          // the task was performed.
          window.setTimeout(() => {
              dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusAssignedCommittee', 'complete');
              dispatch.filingRequirements.checkFilingRequirements(
                rootState.filingRequirements.resolution
              );
            }, 200);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ' Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusAssignedCommittee', 'error');
        });
    },

    async saveResolutionLegislativeType(legislativeType, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;

      dispatch.filingRequirements.setResolutionLegislativeType(legislativeType);
      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusLegislativeType', 'loading');

      axios.put(`/api/v1/resolutions/${resolutionId}.json`, {
        access_token_lpo: accessToken,
        resolution: {
          legislative_type: legislativeType
        }
      })
        .then(() => {
          // Delay marking the request as "complete" a short time so the user has a moment to see
          // the task was performed.
          window.setTimeout(() => {
              dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusLegislativeType', 'complete');
            }, 200);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ' Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusLegislativeType', 'error');
        });
    },

    async saveResolutionHouseOfInitialAction(houseOfInitialAction, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;

      dispatch.filingRequirements.setResolutionHouseOfIntialAction(houseOfInitialAction);
      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusHouseOfInitialAction', 'loading');

      axios.put(`/api/v1/resolutions/${resolutionId}.json`, {
        access_token_lpo: accessToken,
        resolution: {
          house_of_initial_action_id: houseOfInitialAction
        }
      })
        .then(() => {
          // Delay marking the request as "complete" a short time so the user has a moment to see
          // the task was performed.
          window.setTimeout(() => {
              dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusHouseOfInitialAction', 'complete');
              dispatch.filingRequirements.checkFilingRequirements(
                rootState.filingRequirements.resolution
              );
            }, 200);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ' Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusHouseOfInitialAction', 'error');
        });
    },

    async saveResolutionNumber(data, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}.json`;

      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'loading');

      axios.put(url, { ...data, access_token_lpo: accessToken })
        .then((response) => {
          if (response.status === 200) {
            // Fetch the newly assigned resolution number
            axios.get(url)
              // eslint-disable-next-line no-shadow
              .then(({ data }) => {
                const newResolutionNumber = data.number_with_prefix;

                dispatch.filingRequirements.setResolutionNumber(newResolutionNumber);

                window.setTimeout(() => {
                    dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'complete');

                    // Reload page as nav and sidebar are not React components.
                    const currentPath = window.location.href;
                    window.location.href = currentPath;
                  }, 200);
              })
              .catch(() => {
                dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'error');
              });
          } else {
            dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'error');
          }
        })
        .catch(() => {
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'error');
        });
    },

    async fetchTopics(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/topics.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const topics = response.data;
          dispatch.filingRequirements.setTopics(topics);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong on our end. Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusTopic', 'error');
        });
    },

    async fetchHouses(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/houses.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const houses = response.data;
          dispatch.filingRequirements.setHouses(houses);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong on our end. Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusHouseOfInitialAction', 'error');
        });
    },

    async fetchCommittees(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/legislative_committees.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const committees = response.data;
          dispatch.filingRequirements.setCommittees(committees);
        })
        .catch(() => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong on our end. Please refresh the page and try again.'
          );
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusAssignedCommittee', 'error');
        });
    },

    async fetchResolutionTypeLastResolutionNumberAsync(resolution, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      if (resolution && resolution.id !== undefined && resolution.id !== null) {
        const resolutionTypeId = resolution.resolution_type.id;

        axios.get(`/api/v1/resolution_types/${resolutionTypeId}.json`, { params: { access_token_lpo: accessToken } })
          .then(({ data }) => {
            const lastResolutionNumber = `${data.prefix}${data.last_resolution_number}`;

            dispatch.filingRequirements.setLastResolutionNumber(lastResolutionNumber);
          })
          .catch(() => {
            dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResolutionNumber', 'error');
          });
      }
    },

    // ReadyForTranslationContainer
    // ----------------------------------------------------------------------
    async markReadyForTranslation(data, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/mark_ready_for_translation.json`;

      axios.patch(url, { ...data, access_token_lpo: accessToken })
        .then((response) => {
          if (response.status === 200) {
            const result = response.data.metadata.updated;

            if (result.ready_for_translation !== undefined) {
              dispatch.filingRequirements.setReadyForTranslationCheckboxState('readyForTranslation', result.ready_for_translation);
            }
          }
        })
        .catch((error) => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ` Error: ${JSON.stringify(error.response.data.message)}`
          );
        });
    },

    // OfficerReportsContainer
    // ----------------------------------------------------------------------
    async markOfficerReport(data, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/mark_officer_report.json`;

      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusOfficerReports', 'loading');

      axios.patch(url, { ...data, access_token_lpo: accessToken })
        .then((response) => {
          if (response.status === 200) {
            const result = response.data.metadata.updated;

            if (result.resolution_report_sent !== undefined) {
              dispatch.filingRequirements.setOfficerReportsCheckboxState('resolutionReportSent', result.resolution_report_sent);

              // Short delay for visual impact
              setTimeout(() => {
                dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusOfficerReports', 'incomplete');
              }, 200);
            } else if (result.conflict_report_sent !== undefined) {
              dispatch.filingRequirements.setOfficerReportsCheckboxState('conflictReportSent', result.conflict_report_sent);

              // Short delay for visual impact
              setTimeout(() => {
                dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusOfficerReports', 'incomplete');
              }, 200);
            }

            // Conditionally update status indicator
            if (response.data.metadata.allReportsSent !== undefined
              && response.data.metadata.allReportsSent === true) {
              // Short delay for visual impact
              setTimeout(() => {
                dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusOfficerReports', 'complete');
              }, 300);
            }
          }
        })
        .catch((error) => {
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusOfficerReports', 'error');
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ` Error: ${JSON.stringify(error.response.data.message)}`
          );
        });
    },

    // ResearchUrlForm
    // ----------------------------------------------------------------------
    async attachResearchUrl(data, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/attach_research_url.json`;

      dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResearchUrl', 'loading');

      axios.patch(url, { ...data, access_token_lpo: accessToken })
        .then((response) => {
          if (response.status === 200) {
            dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResearchUrl', 'complete');
            dispatch.filingRequirements.setResearchUrlAsAttached(response.data.metadata.url);
          }
        })
        .catch((error) => {
          dispatch.filingRequirements.setPrefilingStatus('filingRequirementStatusResearchUrl', 'error');
          dispatch.filingRequirements.setResearchUrlModalErrorMessage(
          `Error: ${JSON.stringify(error.response.data.message)}`
          );
        });
    },

    async clearResearchUrlModalErrors() {
      dispatch.filingRequirements.resetResearchUrlModalErrors();
    },

    async markResearchUrlAsClosed() {
      dispatch.filingRequirements.clearCloseResearchUrlModal();
    },

    // FileResolutionsForm
    // ----------------------------------------------------------------------
    async fileResolution({ payload, currentUserId }, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/incomplete_prefiling_requirements.json`;
      const filingUrl = `/api/v1/resolutions/${resolutionId}/complete_filing.json`;

      axios.get(url, { params: { access_token_lpo: accessToken } })
        .then(({ data }) => {
          const incomplete = data.metadata.incomplete_requirements;
          const messages = [];
          messages.push(`This resolution has not gone through the complete pre-filing process.

The following pre-filing steps are incomplete:

${incomplete.join(', ')}`);
          if (!data.metadata.human_translated) {
            messages.push("This resolution doesn't have a human translation yet.");
          }

          if (incomplete.length > 0 && payload.incomplete_requirements_notice_shown === false) {
            dispatch.filingRequirements.setFileResolutionsModalNotice(messages);
          } else if (payload.incomplete_requirements_notice_shown === true
            && payload.confirmSubmit === true) {
            fileResolution(dispatch, accessToken, currentUserId, resolutionId, filingUrl);
          } else if (incomplete.length === 0) {
            fileResolution(dispatch, accessToken, currentUserId, resolutionId, filingUrl);
          }
        })
        .catch((error) => {
          const errorContext = error.response === undefined
            ? error : JSON.stringify(error.response.data.message);

          dispatch.filingRequirements.setFileResolutionsModalErrors(
            `Error: ${errorContext}`
          );
        });
    },

    async checkFilingRequirements(resolution, rootState) {
      const resolutionId = rootState.filingRequirements.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/check_filing_requirements.json`;
      axios.get(url, { params: { access_token_lpo: accessToken } })
        .then(({ data }) => {
          if (data.metadata !== undefined
            && data.metadata.incomplete_filing_requirements !== undefined) {
            const incompleteRequirements = data.metadata.incomplete_filing_requirements;
            if (incompleteRequirements.length === 0) {
              dispatch.filingRequirements.setFilingRequirementsAsMet();
            }
          }
        })
        .catch((error) => {
          dispatch.filingRequirements.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ` Error: ${JSON.stringify(error.response.data.message)}`
          );
        });
    },

    async clearFileResolutionsModalNotice() {
      dispatch.filingRequirements.resetFileResolutionsModalNotice();
    },

    async hydrateDataResolution(resolution) {
      dispatch.filingRequirements.setResolution(resolution);
      dispatch.filingRequirements.fetchResolutionTypeLastResolutionNumberAsync(resolution);
      dispatch.filingRequirements.checkFilingRequirements(resolution);
    },

    async hydrateDataRequirement() {
      dispatch.filingRequirements.fetchTopics();
      dispatch.filingRequirements.fetchHouses();
      dispatch.filingRequirements.fetchCommittees();
    }
  })
};

export default FilingRequirementsModel;
