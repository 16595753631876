import React from 'react';
import PropTypes from 'prop-types';

function WarningAlert(props) {
  return props.messages.map((message) => (
    <div className="alert alert--warning" role="alert" key={message}>
      <p>{message}</p>
    </div>
    ));
}

WarningAlert.propTypes = {
  messages: PropTypes.instanceOf(Array)
};

export default WarningAlert;
