import axios from 'axios';

const ArchiveResolutionsModel = {
  state: {
    committees: [],
    errorMessage: '',
    noticeMessage: '',
    successMessage: '',
    resolution: {
      id: null,
      resolutionType: {
        id: null,
        prefix: null,
        code: null,
      },
      filingStatus: 'prefiled',
      signedCopyReceived: false,
      resolutionReportSent: false,
      conflictReportSent: false,
      researchUrl: null,
      lastResolutionNumber: null,
      assignedLegislativeCommitteeId: null,
      assignedLegislativeCommitteeName: null,
      topicId: null,
      topic: null,
      resolutionNumber: null,
      legislativeType: null,
      houseOfInitialAction: null,
      titleWithPrefixedNumber: null,
      filingRequirementStatusResolutionNumber: 'incomplete',
      filingRequirementStatusAssignedCommittee: 'incomplete',
      filingRequirementStatusTopic: 'incomplete',
      filingRequirementStatusLegislativeType: 'incomplete',
      filingRequirementStatusHouseOfInitialAction: 'incomplete',
      filingRequirementStatusSignedCopyReceived: 'incomplete',
      filingRequirementStatusResearchUrl: 'incomplete',
      filingRequirementStatusOfficerReports: 'incomplete',
    },
    showErrors: false,
    showSuccessMessages: false,
    showResearchUrlModalErrors: false,
    closeResearchUrlModal: false,
    showFileResolutionsModalErrors: false,
    showFileResolutionsModalNotice: false,
    closeFileResolutionsModal: false,
    filingRequirementsMet: false,
    topics: [],
    houses: [],
  },

  reducers: {
    setResearchUrlAsAttached(state, url) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          researchUrl: url,
        },
        closeResearchUrlModal: true
      };
    },

    resetResearchUrlModalErrors(state) {
      return {
        ...state,
        errorMessage: '',
        showResearchUrlModalErrors: false,
      };
    },

    clearCloseResearchUrlModal(state) {
      return {
        ...state,
        closeResearchUrlModal: false
      };
    },

    setLastResolutionNumber(state, lastResolutionNumber) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          lastResolutionNumber
        }
      };
    },

    setResolutionTopic(state, newTopic) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          topicId: newTopic.value,
          topic: newTopic.label
        }
      };
    },

    setTopics(state, topics) { return { ...state, topics }; },
    setPrefilingStatus(state, attribute, loadingStatus) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          [attribute]: loadingStatus
        }
      };
    },

    setResolution(state, resolution) {
      return {
        ...state,
        resolution: {
          id: resolution.id,
          resolutionType: {
            id: resolution.resolution_type.id,
            prefix: resolution.resolution_type_prefix,
            code: resolution.resolution_type_code,
          },
          assignedLegislativeCommitteeId: resolution.assigned_legislative_committee_id,
          assignedLegislativeCommitteeName: resolution.assigned_legislative_committee_name,
          topicId: resolution.topic_id,
          topic: resolution.topic,
          resolutionNumber: resolution.number_with_prefix,
          legislativeType: resolution.legislative_type,
          houseOfInitialAction: resolution.house_of_initial_action_id,
          titleWithPrefixedNumber: resolution.title_with_prefixed_number,
          filingRequirementStatusResolutionNumber:
          resolution.filing_requirement_status_resolution_number,
          filingRequirementStatusTopic: resolution.filing_requirement_status_topic,
          filingRequirementStatusAssignedCommittee:
          resolution.filing_requirement_status_assigned_committee,
          filingRequirementStatusLegislativeType:
          resolution.filing_requirement_status_legislative_type,
          filingRequirementStatusHouseOfInitialAction:
          resolution.filing_requirement_status_house_of_initial_action,
          filingRequirementStatusOfficerReports:
          resolution.filing_requirement_status_officer_reports,
          filingRequirementStatusResearchUrl:
          resolution.filing_requirement_status_research_url,
          resolutionReportSent: resolution.checklist.resolution_report_sent,
          conflictReportSent: resolution.checklist.conflict_report_sent,
          researchUrl: resolution.research_url,
        }
      };
    },

    setErrorMessage(state, message) {
      return { ...state, showErrors: true, errorMessage: message };
    },

    setSuccessMessage(state, message) {
      return { ...state, showSuccessMessages: true, successMessage: message };
    },

    setResearchUrlModalErrorMessage(state, message) {
      return { ...state, showResearchUrlModalErrors: true, errorMessage: message };
    },

  },

  effects: (dispatch) => ({
    async saveResolutionTopic(topic, rootState) {
      const resolutionId = rootState.archiveResolutions.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const topicId = topic.value;

      dispatch.archiveResolutions.setResolutionTopic(topic);
      dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusTopic', 'loading');

      axios.put(`/api/v1/resolutions/${resolutionId}.json`, {
        access_token_lpo: accessToken,
        resolution: {
          topic_id: topicId
        }
      })
        .then(() => {
          // Delay marking the request as "complete" a short time so the user has a moment to see
          // the task was performed.
          window.setTimeout(() => {
              dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusTopic', 'complete');
            }, 200);
        })
        .catch(() => {
          dispatch.archiveResolutions.setErrorMessage(
            'Something went wrong and we were unable to save your changes.'
            + ' Please refresh the page and try again.'
          );
          dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusTopic', 'error');
        });
    },

    async fetchTopics(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/topics.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const topics = response.data;
          dispatch.archiveResolutions.setTopics(topics);
        })
        .catch(() => {
          dispatch.archiveResolutions.setErrorMessage(
            'Something went wrong on our end. Please refresh the page and try again.'
          );
          dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusTopic', 'error');
        });
    },

    // ResearchUrlForm
    // ----------------------------------------------------------------------
    async attachResearchUrl(data, rootState) {
      const resolutionId = rootState.archiveResolutions.resolution.id;
      const { accessToken } = rootState.accessTokensModel;
      const url = `/api/v1/resolutions/${resolutionId}/attach_research_url.json`;

      dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusResearchUrl', 'loading');

      axios.patch(url, { ...data, access_token_lpo: accessToken })
        .then((response) => {
          if (response.status === 200) {
            dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusResearchUrl', 'complete');
            dispatch.archiveResolutions.setResearchUrlAsAttached(response.data.metadata.url);
          }
        })
        .catch((error) => {
          dispatch.archiveResolutions.setPrefilingStatus('filingRequirementStatusResearchUrl', 'error');
          dispatch.archiveResolutions.setResearchUrlModalErrorMessage(
            `Error: ${JSON.stringify(error.response.data.message)}`
          );
        });
    },

    async clearResearchUrlModalErrors() {
      dispatch.archiveResolutions.resetResearchUrlModalErrors();
    },

    async markResearchUrlAsClosed() {
      dispatch.archiveResolutions.clearCloseResearchUrlModal();
    },

    async hydrateDataResolution(resolution) {
      dispatch.archiveResolutions.setResolution(resolution);
    },

    async hydrateDataRequirement() {
      dispatch.archiveResolutions.fetchTopics();
    }
  })
};

export default ArchiveResolutionsModel;
