import axios from 'axios';

const PrefileResolutionFormModel = {
  state: {
    resolutionTypes: [],
    errorMessage: '',
    showErrors: false,
    resolution: {
      id: null,
      resolutionType: {
        id: null,
        prefix: null,
        title: null,
        code: '',
      },
      resolutionNumber: '',
      lastResolutionNumber: null,
      autoGenerateNumber: null,
      proposer: {
        id: '',
        actorType: '',
        actorId: null,
        actorName: ''
      },
      submitter: {
        id: null,
        actorName: null,
        actorEmail: null,
      },
      endorsers: [],
      supporters: [],
      filed: false,
    },
    proposerBaseUrl: null,
    proposers: [],
    endorsers: [],
    supporters: [],
    selectProposer: true,
    selectEndorsers: false,
  },

  reducers: {
    setErrorMessage(state, message) {
      return { ...state, showErrors: true, errorMessage: message };
    },

    setResolution(state, resolution) {
      return {
        ...state,
        resolution: {
          id: resolution.id,
          resolutionType: {
            id: resolution.resolution_type ? resolution.resolution_type.id : null,
            prefix: resolution.resolution_type ? resolution.resolution_type.prefix : null,
            code: resolution.resolution_type ? resolution.resolution_type.code : 'interim_body',
            title: resolution.resolution_type ? resolution.resolution_type.title : null
          },
          resolutionNumber: resolution.resolution_number || '',
          autoGenerateNumber: resolution.auto_generate_resolution_number || '',
          proposer: {
            id: resolution.proposer ? resolution.proposer.id : '',
            actorType: resolution.proposer ? resolution.proposer.actor_type : '',
            actorId: resolution.proposer ? resolution.proposer.actor_id : null,
            actorName: resolution.proposer ? resolution.proposer.actor_name : ''
          },
          submitter: {
            id: resolution.submitter ? resolution.submitter.id : null,
            actorName: resolution.submitter ? resolution.submitter.actor_name : null,
            actorEmail: resolution.submitter ? resolution.submitter.actor_email : null
          },
          endorsers: resolution.endorsers.map((endorser) => ({
            label: endorser.actor_name,
            value: `${endorser.actor_name}---${endorser.actor_id || ' '}---${endorser.id}`
          })),
          supporters: resolution.supporters.map((supporter) => ({
            label: supporter.actor_name,
            value: `${supporter.actor_name}---${supporter.actor_id || ' '}---${supporter.id}`
          })),
          filed: resolution.filed,
        }
      };
    },

    setLastResolutionNumber(state, lastResolutionNumber) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          lastResolutionNumber
        }
      };
    },

    setResolutionTypes(state, resolutionTypes) { return { ...state, resolutionTypes }; },

    setResolutionType(state, resolutionType) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          resolutionType: {
            ...state.resolution.resolutionType,
            id: resolutionType.value,
            prefix: resolutionType.data.prefix,
            title: resolutionType.label,
            code: resolutionType.data.code
          },
        }
      };
    },

    setResolutionProposer(state, proposer) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          proposer: {
            ...state.resolution.proposer,
            actorType: proposer.data.actorType,
            actorId: proposer.value,
            actorName: proposer.label
          }
        }
      };
    },

    setResolutionEndorsers(state, endorsers) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          endorsers
        }
      };
    },

    setResolutionSupporters(state, supporters) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          supporters
        }
      };
    },

    setResolutionNumber(state, resolutionNumber) {
      return {
        ...state,
        resolution: {
          ...state.resolution,
          resolutionNumber
        }
      };
    },

    setProposerBaseUrl(state, baseUrl) {
      return {
        ...state,
        proposerBaseUrl: baseUrl
      };
    },

    setProposers(state, proposers) { return { ...state, proposers }; },

    setEndorsers(state, endorsers) { return { ...state, endorsers }; },

    setSupporters(state, supporters) { return { ...state, supporters }; },

    setSelectProposer(state) {
      const resolutionTypePrefix = state.resolution.resolutionType.prefix;
      let selectProposer = true;

      if (resolutionTypePrefix === 'X') {
        selectProposer = false;
      }
      return { ...state, selectProposer };
    },

    setSelectEndorsers(state) {
      const resolutionTypePrefix = state.resolution.resolutionType.prefix;
      let selectEndorsers = false;

      if (resolutionTypePrefix === 'B' || resolutionTypePrefix === 'D') {
        selectEndorsers = true;
      }
      return { ...state, selectEndorsers };
    }
  },

  effects: (dispatch) => ({
    async fetchResolutionTypes(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/resolution_types.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const resolutionTypes = response.data;
          dispatch.prefileResolutionForm.setResolutionTypes(resolutionTypes);
        })
        .catch(() => {
          dispatch.prefileResolutionForm.setErrorMessage(
            'Something went wrong when fetching resolution types. Please refresh the page and try again.'
          );
        });
    },

    async fetchResolutionTypeLastResolutionNumberAsync(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      const resolutionTypeId = rootState.prefileResolutionForm.resolution.resolutionType.id;
      if (resolutionTypeId === null) {
        dispatch.prefileResolutionForm.setLastResolutionNumber('none');
        return;
      }

      axios.get(`/api/v1/resolution_types/${resolutionTypeId}.json`, { params: { access_token_lpo: accessToken } })
        .then(({ data }) => {
          let lastResolutionNumber = `${data.prefix}${data.last_resolution_number}`;
          if (data.last_resolution_number === null) {
            lastResolutionNumber = 'none';
          }

          dispatch.prefileResolutionForm.setLastResolutionNumber(lastResolutionNumber);
        })
        .catch(() => {
          dispatch.prefileResolutionForm.setErrorMessage(
            'Something went wrong when fetch the last resolution number. Please refresh the page and try again.'
          );
        });
    },

    async fetchProposers(term, rootState) {
      const { accessToken } = rootState.accessTokensModel;
      const { code } = rootState.prefileResolutionForm.resolution.resolutionType;
      const baseUrl = rootState.prefileResolutionForm.proposerBaseUrl;
      axios.get(baseUrl, { params: { type: code, access_token: accessToken, term } })
        .then(({ data }) => {
          const proposers = data.map((proposer) => ({
            actorId: proposer.id,
            actorName: proposer.name,
            actorType: proposer.proposer_type
          }));
          dispatch.prefileResolutionForm.setProposers(proposers);
        })
        .catch(() => {
          dispatch.prefileResolutionForm.setErrorMessage(
            'Something went wrong when fetching proposers. Please refresh the page and try again.'
          );
        });
    },

    changeResolutionType(resolutionType) {
      dispatch.prefileResolutionForm.setResolutionType(resolutionType);
      dispatch.prefileResolutionForm.setResolutionNumber('');
      if (resolutionType.data.prefix !== 'X') {
        dispatch.prefileResolutionForm.fetchProposers();
      }
      dispatch.prefileResolutionForm.setResolutionProposer({ value: '', label: '', data: { actorType: '' } });
      dispatch.prefileResolutionForm.fetchResolutionTypeLastResolutionNumberAsync();
      if (resolutionType.data.prefix === 'B' || resolutionType.data.prefix === 'D') {
        dispatch.prefileResolutionForm.setResolutionEndorsers([]);
      }
      dispatch.prefileResolutionForm.setSelectProposer();
      dispatch.prefileResolutionForm.setSelectEndorsers();
    },

    async hydrateResolutionForm(resolution) {
      dispatch.prefileResolutionForm.fetchResolutionTypes();
      dispatch.prefileResolutionForm.setResolution(resolution);
      dispatch.prefileResolutionForm.fetchResolutionTypeLastResolutionNumberAsync();
      dispatch.prefileResolutionForm.setSelectProposer();
      dispatch.prefileResolutionForm.setSelectEndorsers();
    },
  })
};

export default PrefileResolutionFormModel;
