/* eslint-env jquery */

import ReactOnRails from 'react-on-rails';
import AuthenticationsContainer from './components/Authentications/AuthenticationsContainer';
import FileResolutionsContainer from './components/FilingRequirements/FileResolutionsContainer';
import FilingRequirementsContainer from './components/FilingRequirements/FilingRequirementsContainer';
import ResolutionsContainer from './components/FilingRequirements/ResolutionsContainer';
import PrefileResolutionFormFieldsContainer from './components/PrefileResolutionForm/PrefileResolutionFormFieldsContainer';
import ProposedResolutionFormFieldsContainer from './components/ProposedResolutionForm/ProposedResolutionFormFieldsContainer';
import ArchiveResolutionsContainer from './components/ArchiveResolutions/ArchiveResolutionsContainer';
import restoreBaseVersionText from './restore_base_version_text';
import useResolutionTextVersion from './use_resolution_text_version';
import detectHouseMessageTextChange from './detect_house_message_text_change';
import detectCommitteeReportTextChange from './detect_committee_report_text_change';

ReactOnRails.register({
  AuthenticationsContainer,
  FileResolutionsContainer,
  FilingRequirementsContainer,
  ResolutionsContainer,
  PrefileResolutionFormFieldsContainer,
  ProposedResolutionFormFieldsContainer,
  ArchiveResolutionsContainer
});

$(async () => {
  // Load app-specific js
  restoreBaseVersionText();
  useResolutionTextVersion();
  detectHouseMessageTextChange();
  detectCommitteeReportTextChange();
});
