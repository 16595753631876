import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';

class ResolutionsForm extends React.Component {
  componentDidMount() {
    this.props.hydrateDataResolution(this.props.resolution);
  }

  render() {
    return (<div />);
  }
}

const mapDispatch = (dispatch) => ({
  hydrateDataResolution: (resolution) =>
    dispatch.filingRequirements.hydrateDataResolution(resolution),
});

ResolutionsForm.propTypes = {
  hydrateDataResolution: PropTypes.func.isRequired,
  resolution: ResolutionProps.isRequired
};

export default connect(null, mapDispatch)(ResolutionsForm);
