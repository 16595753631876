import axios from 'axios';
/* eslint-disable max-len */

const ProposedResolutionFormModel = {
  state: {
    resolutionTypes: [],
    errorMessage: '',
    showErrors: false,
    /* eslint-disable camelcase */
    proposed_resolution: {
      id: null,
      resolutionType: {
        id: null,
        prefix: null,
        title: null,
        code: '',
      },
      proposer: {
        id: '',
        actorType: '',
        actorId: null,
        actorName: ''
      },
      endorsers: [],
      supporters: [],
      filed: false,
    },
    proposerBaseUrl: null,
    proposers: [],
    endorsers: [],
    supporters: [],
    selectProposer: true,
    selectEndorsers: false,
  },

  reducers: {
    setErrorMessage(state, message) {
      return { ...state, showErrors: true, errorMessage: message };
    },

    setProposedResolution(state, proposed_resolution) {
      return {
        ...state,
        proposed_resolution: {
          id: proposed_resolution.id,
          resolutionType: {
            id: proposed_resolution.resolution_type ? proposed_resolution.resolution_type.id : null,
            prefix: proposed_resolution.resolution_type ? proposed_resolution.resolution_type.prefix : null,
            code: proposed_resolution.resolution_type ? proposed_resolution.resolution_type.code : 'interim_body',
            title: proposed_resolution.resolution_type ? proposed_resolution.resolution_type.title : null
          },
          proposer: {
            id: proposed_resolution.proposer ? proposed_resolution.proposer.id : '',
            actorType: proposed_resolution.proposer ? proposed_resolution.proposer.actor_type : '',
            actorId: proposed_resolution.proposer ? proposed_resolution.proposer.actor_id : null,
            actorName: proposed_resolution.proposer ? proposed_resolution.proposer.actor_name : ''
          },
          endorsers: proposed_resolution.endorsers.map((endorser) => ({
            label: endorser.actor_name,
            value: `${endorser.actor_name}---${endorser.actor_id || ' '}---${endorser.id}`
          })),
          supporters: proposed_resolution.supporters.map((supporter) => ({
            label: supporter.actor_name,
            value: `${supporter.actor_name}---${supporter.actor_id || ' '}---${supporter.id}`
          })),
          approved: proposed_resolution.approved,
        }
      };
    },

    setProposedResolutionTypes(state, resolutionTypes) { return { ...state, resolutionTypes }; },

    setProposedResolutionType(state, resolutionType) {
      return {
        ...state,
        proposed_resolution: {
          ...state.proposed_resolution,
          resolutionType: {
            ...state.proposed_resolution.resolutionType,
            id: resolutionType.value,
            prefix: resolutionType.data.prefix,
            title: resolutionType.label,
            code: resolutionType.data.code
          },
        }
      };
    },

    setProposedResolutionProposer(state, proposer) {
      return {
        ...state,
        proposed_resolution: {
          ...state.proposed_resolution,
          proposer: {
            ...state.proposed_resolution.proposer,
            actorType: proposer.data.actorType,
            actorId: proposer.value,
            actorName: proposer.label
          }
        }
      };
    },

    setProposedResolutionEndorsers(state, endorsers) {
      return {
        ...state,
        proposed_resolution: {
          ...state.proposed_resolution,
          endorsers
        }
      };
    },

    setProposedResolutionSupporters(state, supporters) {
      return {
        ...state,
        proposed_resolution: {
          ...state.proposed_resolution,
          supporters
        }
      };
    },

    setProposerBaseUrl(state, baseUrl) {
      return {
        ...state,
        proposerBaseUrl: baseUrl
      };
    },

    setProposers(state, proposers) { return { ...state, proposers }; },

    setEndorsers(state, endorsers) { return { ...state, endorsers }; },

    setSupporters(state, supporters) { return { ...state, supporters }; },

    setSelectProposer(state) {
      const resolutionTypePrefix = state.proposed_resolution.resolutionType.prefix;
      let selectProposer = true;

      if (resolutionTypePrefix === 'X') {
        selectProposer = false;
      }
      return { ...state, selectProposer };
    },

    setSelectEndorsers(state) {
      const resolutionTypePrefix = state.proposed_resolution.resolutionType.prefix;
      let selectEndorsers = false;

      if (resolutionTypePrefix === 'B' || resolutionTypePrefix === 'D') {
        selectEndorsers = true;
      }
      return { ...state, selectEndorsers };
    }
  },

  effects: (dispatch) => ({
    async fetchResolutionTypes(payload, rootState) {
      const { accessToken } = rootState.accessTokensModel;

      axios.get('/api/v1/resolution_types.json', { params: { access_token_lpo: accessToken } })
        .then((response) => {
          const resolutionTypes = response.data;
          dispatch.proposedResolutionForm.setProposedResolutionTypes(resolutionTypes);
        })
        .catch(() => {
          dispatch.proposedResolutionForm.setErrorMessage(
            'Something went wrong when fetching resolution types. Please refresh the page and try again.'
          );
        });
    },

    async fetchProposers(term, rootState) {
      const { accessToken } = rootState.accessTokensModel;
      const { code } = rootState.proposedResolutionForm.proposed_resolution.resolutionType;
      const baseUrl = rootState.proposedResolutionForm.proposerBaseUrl;
      axios.get(baseUrl, { params: { type: code, access_token: accessToken, term } })
        .then(({ data }) => {
          const proposers = data.map((proposer) => ({
            actorId: proposer.id,
            actorName: proposer.name,
            actorType: proposer.proposer_type
          }));
          dispatch.proposedResolutionForm.setProposers(proposers);
        })
        .catch(() => {
          dispatch.proposedResolutionForm.setErrorMessage(
            'Something went wrong when fetching proposers. Please refresh the page and try again.'
          );
        });
    },

    changeResolutionType(resolutionType) {
      dispatch.proposedResolutionForm.setProposedResolutionType(resolutionType);
      if (resolutionType.data.prefix !== 'X') {
        dispatch.proposedResolutionForm.fetchProposers();
      }
      dispatch.proposedResolutionForm.setProposedResolutionProposer({ value: '', label: '', data: { actorType: '' } });
      if (resolutionType.data.prefix === 'B' || resolutionType.data.prefix === 'D') {
        dispatch.proposedResolutionForm.setProposedResolutionEndorsers([]);
      }
      dispatch.proposedResolutionForm.setSelectProposer();
      dispatch.proposedResolutionForm.setSelectEndorsers();
    },

    async hydrateProposedResolutionForm(proposed_resolution) {
      dispatch.proposedResolutionForm.fetchResolutionTypes();
      dispatch.proposedResolutionForm.setProposedResolution(proposed_resolution);
      dispatch.proposedResolutionForm.setSelectProposer();
      dispatch.proposedResolutionForm.setSelectEndorsers();
    },
  })
};

export default ProposedResolutionFormModel;
