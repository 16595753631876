import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LegislativeCommitteeForm from './LegislativeCommitteeForm';
import LegislativeTypeForm from './LegislativeTypeForm';
import HouseOfInitialActionForm from './HouseOfInitialActionForm';
import ErrorAlert from '../ErrorAlert';
import SuccessAlert from '../SuccessAlert';
import ResolutionNumberForm from './ResolutionNumberForm';
import ReadyForTranslationContainer from './ReadyForTranslationContainer';

class FilingRequirementsForm extends React.Component {
  componentDidMount() {
    this.props.hydrateDataRequirement();
  }

  render() {
    return (
      <div className="filing-requirements-form">
        <h2>Filing requirements</h2>
        { this.props.showErrors && <ErrorAlert message={this.props.errorMessage} /> }
        { this.props.showSuccessMessages && <SuccessAlert message={this.props.successMessage} /> }
        <table className="table--horizontal-borders table--roomy table--no-hover">
          <tbody>
            <ResolutionNumberForm />
            <LegislativeCommitteeForm />
            <LegislativeTypeForm />
            <HouseOfInitialActionForm />
            <ReadyForTranslationContainer />
          </tbody>
        </table>
      </div>
    );
  }
}

const mapState = (state) => ({
  errorMessage: state.filingRequirements.errorMessage,
  successMessage: state.filingRequirements.successMessage,
  showErrors: state.filingRequirements.showErrors,
  showSuccessMessages: state.filingRequirements.showSuccessMessages,
});

const mapDispatch = (dispatch) => ({
  hydrateDataRequirement: () => dispatch.filingRequirements.hydrateDataRequirement(),
});

FilingRequirementsForm.propTypes = {
  hydrateDataRequirement: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  showErrors: PropTypes.bool.isRequired,
  showSuccessMessages: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(FilingRequirementsForm);
