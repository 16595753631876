/* eslint-env jquery */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */

export default function detectCommitteeReportTextChange() {
  $(window).on('load', () => {
    if (window.location.pathname.match(/committee_reports/) && $('#restore-text-button').length) {
      CKEDITOR.on('instanceReady', () => {
        const startingText = getEditorHTMLContents();
        const adopt = $('#resolution_recommended_action_adopt');
        const adoptWithAmendment = $('#resolution_recommended_action_adopt_with_amendment');
        const adoptSubstitute = $('#resolution_recommended_action_adopt_substitute');
        const saveButton = $('#commit_actions_button');

        textChangeListener();

        function getEditorHTMLContents() {
          return $('iframe').contents().find('body').html();
        }

        function getEditorTextContents() {
          return $('iframe').contents().find('body').text();
        }

        function textChangeListener() {
          const textWindow = $('iframe')[0].contentWindow.document;

          $(textWindow).off('keyup paste change', compareText);
          $(textWindow).on('keyup paste change', compareText);       
          window.ckeditorInstance.on('change', compareText);
        }

        $(adopt).on('click', () => {
          hideWarnings();
          enableSave();
          compareText();
        });

        $(adoptWithAmendment).on('click', () => {
          hideWarnings();
          enableSave();
          compareText();
        });

        $(adoptSubstitute).on('click', () => {
          hideWarnings();
          enableSave();
          compareText();
        });

        $('#restore-text-button').on('click', () => {
          if ($(adoptWithAmendment).is(':checked')) {
            compareText();
          } else if ($(adoptSubstitute).is(':checked')) {
            compareText();
          } else {
            hideWarnings();
            enableSave();
          }
        });

        $('#resolution_recommended_action_reject,#resolution_recommended_action_take_no_further_action,#resolution_recommended_action_refer').on('click', () => {
          hideWarnings();
          compareText();
        });

        function compareText() {
          let currentText = getEditorHTMLContents();

          hideWarnings();
          enableSave();

          if (getEditorTextContents().length === 0) {
            showNoTextWarning();
            disableSave();
          } else if ((startingText !== currentText) && $(adopt).is(':checked')) {
            disableSave();
            showAdoptTextChangeWarning();
          } else if ((startingText === currentText) && $(adoptWithAmendment).is(':checked')) {
            showAdoptwithAmendmentWarning();
            disableSave();
          } else if ((startingText === currentText) && $(adoptSubstitute).is(':checked')) {
            showAdoptSubstituteWarning();
            disableSave();
          } else if ((startingText !== currentText) && (!$(adoptSubstitute).is(':checked')) && (!$(adoptWithAmendment).is(':checked')) && (!$(adopt).is(':checked'))) {
            showGeneralTextChangeWarning();
          }
        }
    
        function disableSave() {
          saveButton.prop('disabled', true);
        }

        function enableSave() {
          saveButton.prop('disabled', false);
        }

        function showAdoptTextChangeWarning() {
          $('.adopt_and_text_changes').removeClass('hide');
          $('.adopt_and_text_changes').addClass('show');
        }

        function showAdoptwithAmendmentWarning() {
          $('.adopt_w_amendment').removeClass('hide');
          $('.adopt_w_amendment').addClass('show');
        }

        function showAdoptSubstituteWarning() {
          $('.adopt_subs').removeClass('hide');
          $('.adopt_subs').addClass('show');
        }

        function showNoTextWarning() {
          $('.empty_text').removeClass('hide');
          $('.empty_text').addClass('show');
        }

        function showGeneralTextChangeWarning() {
          $('.text_changed').removeClass('hide');
          $('.text_changed').addClass('show');
        }

        function hideWarnings() {
          $('.alert--danger').addClass('hide');
          $('.alert--danger').removeClass('show');
        }
      });
    }
  });
}
