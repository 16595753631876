import React from 'react';
import { Provider } from 'react-redux';
import FilingRequirementsForm from './FilingRequirementsForm';

function FilingRequirementsContainer() {
  return (
    <Provider store={window.store}>
      <FilingRequirementsForm />
    </Provider>
  );
}

export default FilingRequirementsContainer;
