import React from 'react';
import PropTypes from 'prop-types';

function ErrorAlert(props) {
  return (
    <div className="alert alert--danger" role="alert">
      <p>{props.message}</p>
    </div>
  );
}

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorAlert;
