import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';

class EndorsersField extends React.Component {
  constructor(props) {
    super(props);

    this.handleEndorsersSelectInputChange = this.handleEndorsersSelectInputChange.bind(this);
    this.handleEndorsersChange = this.handleEndorsersChange.bind(this);
    this.handleEndorsersInputChange = this.handleEndorsersInputChange.bind(this);
  }

  handleEndorsersSelectInputChange(item) {
    this.props.fetchProposers(item);
  }

  handleEndorsersChange(item) {
    this.props.setResolutionEndorsers(item || []);
  }

  handleEndorsersInputChange(item) {
    const endorser = { value: item, label: item };
    this.props.setEndorsers([endorser]);
  }

  render() {
    const proposers = this.props.proposers.map((proposer) => ({
      value: `${proposer.actorName}---${proposer.actorId}`, 
      label: proposer.actorName
    }));

    const selectedValues = this.props.resolution.endorsers;
    const selectClass = this.props.selectEndorsers ? 'form-group' : 'hide';
    const inputClass = this.props.selectEndorsers ? 'hide' : 'form-group';

    return (
      <div>
        <div className={selectClass}>
          <label>Endorsers</label>
          <Select
            options={proposers}
            value={selectedValues}
            isMulti
            placeholder="Please select..."
            onInputChange={this.handleEndorsersSelectInputChange}
            onChange={this.handleEndorsersChange}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                text: 'orangered',
                primary25: '#ddd',
                primary: '#3e8ede',
              },
            })}
          />
          <span>Please select at least two Endorsers.</span>
        </div>
        <div className={inputClass}>
          <label>Endorsers</label>
          <Select
            options={this.props.endorsers}
            value={selectedValues}
            isMulti
            placeholder="Please select..."
            onInputChange={this.handleEndorsersInputChange}
            onChange={this.handleEndorsersChange}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                text: 'orangered',
                primary25: '#ddd',
                primary: '#3e8ede',
              },
            })}
          />
        </div>
        <select 
          className="hide" 
          name="resolution[endorsers][]" 
          multiple
          readOnly
          value={this.props.resolution.endorsers.map((endorser) => (endorser.value))}
        >
          {
            this.props.resolution.endorsers.map((endorser) => 
            (<option value={endorser.value} key={endorser.value} label={endorser.label} />))
          }
        </select>
      </div>
    );
  }
}

const mapState = (state) => ({
  resolution: state.prefileResolutionForm.resolution,
  proposers: state.prefileResolutionForm.proposers,
  endorsers: state.prefileResolutionForm.endorsers,
  selectEndorsers: state.prefileResolutionForm.selectEndorsers,
});

const mapDispatch = (dispatch) => ({
  fetchProposers: (term) => dispatch.prefileResolutionForm.fetchProposers(term),
  setResolutionEndorsers: (endorsers) => 
    dispatch.prefileResolutionForm.setResolutionEndorsers(endorsers),
  setEndorsers: (endorsers) => dispatch.prefileResolutionForm.setEndorsers(endorsers),
});

EndorsersField.propTypes = {
  resolution: ResolutionProps.isRequired,
  fetchProposers: PropTypes.func.isRequired,
  setResolutionEndorsers: PropTypes.func.isRequired,
  setEndorsers: PropTypes.func.isRequired,
  proposers: PropTypes.instanceOf(Array).isRequired,
  endorsers: PropTypes.instanceOf(Array).isRequired,
  selectEndorsers: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(EndorsersField);
