import React from 'react';
import { Provider } from 'react-redux';
import ResolutionsForm from './ResolutionsForm';
import ResolutionProps from '../../types/ResolutionProps';

function ResolutionsContainer(props) {
  return (
    <Provider store={window.store}>
      <ResolutionsForm resolution={props.resolution} />
    </Provider>
  );
}

ResolutionsContainer.propTypes = {
  resolution: ResolutionProps.isRequired,
};

export default ResolutionsContainer;
