import PropTypes from 'prop-types';

const ResolutionProps = PropTypes.shape({
      id: PropTypes.number,
      resolutionNumber: PropTypes.string,
      assignedLegislativeCommitteeId: PropTypes.number,
      assignedLegislativeCommitteeName: PropTypes.string,
      topicId: PropTypes.number,
      topic: PropTypes.string,
      legislativeType: PropTypes.string,
      houseOfInitialAction: PropTypes.number,
      filingRequirementStatusResolutionNumber: PropTypes.string,
      filingRequirementStatusAssignedCommittee: PropTypes.string,
      filingRequirementStatusTopic: PropTypes.string,
      filingRequirementStatusLegislativeType: PropTypes.string,
      filingRequirementStatusHouseOfInitialAction: PropTypes.string,
});

export default ResolutionProps;
