import React from 'react';
import { connect } from 'react-redux';
import ArchiveResolutionsStatusIndicator from './ArchiveResolutionsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';
import ResearchUrlFormModal from './ResearchUrlFormModal';

function ResearchUrlForm(props) {
  const researchUrlAttached = (props.resolution.filingRequirementStatusResearchUrl === 'complete');

  return (
    <tr className="align--middle">
      <td className="table--fixed-column text--center filing-requirement__spinner-column">
        <ArchiveResolutionsStatusIndicator
          status={props.resolution.filingRequirementStatusResearchUrl}
        />
      </td>
      <td className="table--fixed-column"><strong>Research</strong></td>
      {researchUrlAttached ? (
        <td>
          <a href={props.resolution.researchUrl} target="_blank" rel="noopener noreferrer">
            {props.resolution.researchUrl}
            &nbsp;
          </a>
          <ResearchUrlFormModal
            triggerButtonText="Edit"
          />
        </td>
        )
        : (
          <td>
            No research URL has been added yet&nbsp;
            <ResearchUrlFormModal
              triggerButtonText="Add now"
            />
          </td>
        )}
    </tr>
  );
}

const mapState = (state) => ({
  resolution: state.archiveResolutions.resolution,
});

ResearchUrlForm.propTypes = {
  resolution: ResolutionProps.isRequired,
};

export default connect(mapState)(ResearchUrlForm);
