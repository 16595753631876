import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import ErrorAlert from '../ErrorAlert';
import ResolutionProps from '../../types/ResolutionProps';

class ResearchUrlFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      url: '',
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidUpdate(previousProps) {
    // Close modal once research URL is added or edited.
    if (previousProps.closeResearchUrlModal !== this.props.closeResearchUrlModal) {
      if (this.props.closeResearchUrlModal === true) {
        this.setState({ modalOpen: false });
        this.props.markResearchUrlAsClosed();
      }
    }
  }

  handleModalOpen() {
    this.setState(() => ({
      modalOpen: true
    }));
  }

  handleModalClose(event) {
    event.preventDefault();

    if (this.props.showResearchUrlModalErrors) {
      this.props.clearResearchUrlModalErrors();
    }

    this.setState(() => ({
      modalOpen: false
    }));
  }

  handleUrlChange(event) {
    const url = event.target.value;

    this.setState(() => ({
      url
    }));
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const data = this.state.url;

    this.props.attachResearchUrl({ url: data });
  }

  render() {
    return (
      <Modal
        trigger={(
          <button
            className="btn btn--small btn--default"
            onClick={this.handleModalOpen}
          >
            {this.props.triggerButtonText}
          </button>
        )}
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
      >
        <Modal.Content>
          <form onSubmit={this.handleFormSubmit}>
            <div className="modal__header">
              Research URL
            </div>
            <div className="modal__content">
              {
                this.props.showResearchUrlModalErrors
                && <ErrorAlert message={this.props.errorMessage} />
              }
              <label>Research URL (Include http:// or https:// in URL provided)</label>
              <input
                type="text"
                name="url"
                className="form-control"
                defaultValue={this.props.resolution.researchUrl}
                onChange={this.handleUrlChange}
              />
            </div>
            <div className="modal__actions">
              <button className="btn btn--default" onClick={this.handleModalClose}>
                Cancel
              </button>
              &nbsp;
              <button className="btn btn--primary">
                Save research URL
              </button>
            </div>
          </form>

        </Modal.Content>
      </Modal>
    );
  }
}

ResearchUrlFormModal.propTypes = {
  resolution: ResolutionProps.isRequired,
  triggerButtonText: PropTypes.string.isRequired,
  attachResearchUrl: PropTypes.func.isRequired,
  markResearchUrlAsClosed: PropTypes.func.isRequired,
  clearResearchUrlModalErrors: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showResearchUrlModalErrors: PropTypes.bool.isRequired,
  closeResearchUrlModal: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
  resolution: state.archiveResolutions.resolution,
  errorMessage: state.archiveResolutions.errorMessage,
  showResearchUrlModalErrors: state.archiveResolutions.showResearchUrlModalErrors,
  closeResearchUrlModal: state.archiveResolutions.closeResearchUrlModal,
});

const mapDispatch = ({
                       archiveResolutions: {
                         attachResearchUrl,
                         clearResearchUrlModalErrors,
                         markResearchUrlAsClosed,
                       }
                     }) => ({
  attachResearchUrl: (data) => attachResearchUrl(data),
  clearResearchUrlModalErrors: (data) => clearResearchUrlModalErrors(data),
  markResearchUrlAsClosed: (data) => markResearchUrlAsClosed(data),
});

export default connect(mapState, mapDispatch)(ResearchUrlFormModal);
