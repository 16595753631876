import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import ArchiveResolutionsStatusIndicator from './ArchiveResolutionsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';

class TopicForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTopicChange = this.handleTopicChange.bind(this);
  }

  handleTopicChange(item) {
    this.props.saveResolutionTopic(item);
  }

  render() {
    const topics = this.props.topics.map((topic) => ({ value: topic.id, label: topic.name }));

    let selectedValue = null;
    if (this.props.resolution.topicId) {
      selectedValue = { value: this.props.resolution.topicId, label: this.props.resolution.topic };
    }

    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center filing-requirement__spinner-column">
          <ArchiveResolutionsStatusIndicator
            status={this.props.resolution.filingRequirementStatusTopic}
          />
        </td>

        <td className="table--fixed-column"><strong>Topic</strong></td>

        <td>
          <Select
            options={topics}
            isSearchable
            placeholder="Please select..."
            value={selectedValue}
            onChange={this.handleTopicChange}
            theme={(theme) => ({
              ...theme,
              colors: {
              ...theme.colors,
                text: 'orangered',
                primary25: '#ddd',
                primary: '#3e8ede',
              },
            })}
          />
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  resolution: state.archiveResolutions.resolution,
  topics: state.archiveResolutions.topics,
});

const mapDispatch = ({ archiveResolutions: { saveResolutionTopic } }) => ({
  saveResolutionTopic: (topic) => saveResolutionTopic(topic),
});

TopicForm.propTypes = {
  resolution: ResolutionProps.isRequired,
  saveResolutionTopic: PropTypes.func.isRequired,
  topics: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapState, mapDispatch)(TopicForm);
