import React from 'react';
import { connect } from 'react-redux';
import ResolutionProps from '../../types/ResolutionProps';

class SubmitterFields extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="form-group">
          <label className="required" htmlFor="resolution_full_name">Full name</label>
          <input
            className="form-control"
            required="required"
            type="text"
            name="resolution[submitter_name]"
            defaultValue={this.props.resolution.submitter.actorName}
          />
        </div>
        <div className="form-group">
          <label className="required" htmlFor="resolution_email_address">Email address</label>
          <input
            className="form-control"
            required="required"
            type="email"
            name="resolution[submitter_email]"
            defaultValue={this.props.resolution.submitter.actorEmail}
          />
        </div>
        <input
          type="hidden"
          name="resolution[submitter_id]"
          defaultValue={this.props.resolution.submitter.id}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  resolution: state.prefileResolutionForm.resolution,
});

SubmitterFields.propTypes = {
  resolution: ResolutionProps.isRequired,
};

export default connect(mapState)(SubmitterFields);
