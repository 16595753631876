import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import FileResolutionsForm from './FileResolutionsForm';
import ResolutionProps from '../../types/ResolutionProps';

function FileResolutionsContainer(props) {
  return (
    <Provider store={window.store}>
      <FileResolutionsForm resolution={props.resolution} currentUserId={props.current_user_id} />
    </Provider>
  );
}

FileResolutionsContainer.propTypes = {
  resolution: ResolutionProps.isRequired,
  current_user_id: PropTypes.number.isRequired,
};

export default FileResolutionsContainer;
