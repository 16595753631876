import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';
import PrefileResolutionFormFields from './PrefileResolutionFormFields';

function PrefileResolutionFormFieldsContainer(props) {
  return (
    <Provider store={window.store}>
      <PrefileResolutionFormFields 
        resolution={props.resolution}
        proposerBaseUrl={props.proposer_base_url}
      />
    </Provider>
  );
}

PrefileResolutionFormFieldsContainer.propTypes = {
  resolution: ResolutionProps.isRequired,
  proposer_base_url: PropTypes.string.isRequired,
};

export default PrefileResolutionFormFieldsContainer;
