import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicatorComplete from '../StatusIndicatorComplete';
import StatusIndicatorLoading from '../StatusIndicatorLoading';
import StatusIndicatorIncomplete from '../StatusIndicatorIncomplete';
import StatusIndicatorError from '../StatusIndicatorError';

function FilingRequirementsStatusIndicator(props) {
  switch (props.status) {
    case 'error':
      return <StatusIndicatorError />;
    case 'incomplete':
      return <StatusIndicatorIncomplete />;
    case 'loading':
      return <StatusIndicatorLoading />;
    case 'complete':
      return <StatusIndicatorComplete />;
    default:
      return <StatusIndicatorIncomplete />;
  }
}

FilingRequirementsStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired
};

export default FilingRequirementsStatusIndicator;
