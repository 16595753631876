import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';
import ErrorAlert from '../ErrorAlert';
import SuccessAlert from '../SuccessAlert';
import TopicForm from './TopicForm';
import ResearchUrlForm from './ResearchUrlForm';

class ArchiveResolutionsForm extends React.Component {
  componentDidMount() {
    this.props.hydrateDataResolution(this.props.resolution);
    this.props.hydrateDataRequirement();
  }

  render() {
    return (
      <div className="filing-requirements-form">
        <h2>Resolution requirements</h2>
        { this.props.showErrors && <ErrorAlert message={this.props.errorMessage} /> }
        { this.props.showSuccessMessages && <SuccessAlert message={this.props.successMessage} /> }
        <table className="table--horizontal-borders table--roomy table--no-hover">
          <tbody>
            <TopicForm />
            <ResearchUrlForm />
          </tbody>
        </table>
      </div>
    );
  }
}

const mapState = (state) => ({
  errorMessage: state.archiveResolutions.errorMessage,
  successMessage: state.archiveResolutions.successMessage,
  showErrors: state.archiveResolutions.showErrors,
  showSuccessMessages: state.archiveResolutions.showSuccessMessages,
});

const mapDispatch = (dispatch) => ({
  hydrateDataResolution: (resolution) =>
    dispatch.archiveResolutions.hydrateDataResolution(resolution),
  hydrateDataRequirement: () => dispatch.archiveResolutions.hydrateDataRequirement(),
});

ArchiveResolutionsForm.propTypes = {
  resolution: ResolutionProps.isRequired,
  hydrateDataResolution: PropTypes.func.isRequired,
  hydrateDataRequirement: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  showErrors: PropTypes.bool.isRequired,
  showSuccessMessages: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(ArchiveResolutionsForm);
