import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';

class ReadyForTranslationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readyForTranslationChecked: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.readyForTranslation !== this.props.readyForTranslation) {
      this.setState({ readyForTranslationChecked: this.props.readyForTranslation });
    }
  }

  handleChange(event) {
    const checkedValue = event.target.value;
    const data = { [checkedValue]: event.target.checked };

    this.props.markReadyForTranslation(data);
  }

  render() {
    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center filing-requirement__spinner-column" aria-hidden="true" />
        <td className="table--fixed-column"><strong>Translation</strong></td>
        <td>
          <form id="filing-requirement__ready-for-translation-form">
            <input type="text" defaultValue={this.props.resolution.resolutionType.id} name="resolution[resolution_type_id]" hidden />
            <div className="flex flex--align-center">
              <div className="controls-stacked space-below--none">
                <div className="checkbox__grouped checkbox__ready_for_translation">
                  <label className="control checkbox" htmlFor="resolution_ready_for_translation">
                    <input
                      type="checkbox"
                      name="resolution[ready_for_translation]"
                      id="resolution_ready_for_translation"
                      value="ready_for_translation"
                      onChange={this.handleChange}
                      checked={this.state.readyForTranslationChecked}
                    />
                    <span className="control-indicator" />
                    Ready for Translation
                  </label>
                </div>
              </div>
            </div>
          </form>
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  resolution: state.filingRequirements.resolution,
  readyForTranslation: state.filingRequirements.resolution.readyForTranslation,
});

const mapDispatch = ({ filingRequirements: { markReadyForTranslation } }) => ({
  markReadyForTranslation: (data) => markReadyForTranslation(data),
});

ReadyForTranslationContainer.propTypes = {
  resolution: ResolutionProps.isRequired,
  markReadyForTranslation: PropTypes.func.isRequired,
  readyForTranslation: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(ReadyForTranslationContainer);
