/* eslint-env jquery */
/* eslint-disable no-undef */

export default function useResolutionTextVersion() {
  if (window.location.pathname.match(/house_messages/) && $('.fa-version-text-button').length) {
    const alert = document.getElementById('textVersionAlert');
    let oldContent = document.createTextNode('old content');
    alert.appendChild(oldContent);

    $('.fa-version-text-button').click(function () { // eslint-disable-line func-names
      const resolutionText = $(this).closest('tr')
                                    .find('.resolution-text')
                                    .html()
                                    .trim();
      const editorId = $('#editor_id').text();
  
      CKEDITOR.instances[editorId].setData(resolutionText);

      const textVersionLabel = $(this).closest('tr').find('h2').text();
      const content = document.createTextNode(`You are now using the ${textVersionLabel} Version`);
      
      $('.alert--success').removeClass('hide');
      $('.alert--danger').removeClass('hide');
      alert.replaceChild(content, oldContent);

      oldContent = content;
    });
  }
}
