import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Popup } from 'semantic-ui-react';
import ErrorAlert from '../ErrorAlert';
import WarningAlert from '../WarningAlert';
import ResolutionProps from '../../types/ResolutionProps';

class FileResolutionsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      incompleteRequirementsNoticeShown: false,
      confirmSubmit: 'no',
      filingButtonDisabled: true,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleConfirmToggle = this.handleConfirmToggle.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Update local state once incomplete requirements notice is shown in modal
    if (prevProps.showFileResolutionsModalNotice
      !== this.props.showFileResolutionsModalNotice) {
      if (this.props.showFileResolutionsModalNotice === true) {
        this.setState({
          incompleteRequirementsNoticeShown: this.props.showFileResolutionsModalNotice,
        });
      }
    }

    if (prevProps.filingRequirementsMet
      !== this.props.filingRequirementsMet) {
      this.setState({
        filingButtonDisabled: false,
      });
    }

    if (prevProps.closeFileResolutionsModal
      !== this.props.closeFileResolutionsModal) {
      this.setState({
        modalOpen: false,
      });
    }
  }

  handleModalOpen() {
    this.setState(() => ({
      modalOpen: true
    }));
  }

  handleModalClose(event) {
    event.preventDefault();

    if (this.props.showFileResolutionsModalNotice) {
      this.props.clearFileResolutionsModalNotice();
      this.setState({
        incompleteRequirementsNoticeShown: false,
        confirmSubmit: 'no',
      });
    }

    this.setState(() => ({
      modalOpen: false
    }));
  }

  handleConfirmToggle(event) {
    const checkedValue = event.target.value;

    this.setState({
      confirmSubmit: checkedValue,
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();

    this.props.fileResolution({
      currentUserId: this.props.currentUserId,
      resolution: this.props.resolution,
      payload: {
        incomplete_requirements_notice_shown: this.state.incompleteRequirementsNoticeShown,
        confirmSubmit: this.state.confirmSubmit === 'yes',
      }
    });
  }

  render() {
    return (
      <Modal
        trigger={(
          <Popup
            content={(
              <div>
                <div>
                  Cannot file a resolution until it has a Resolution Number,
                  an assigned Committee and a House.
                </div>
                <div>You may need to refresh the page for your changes to be reflected.</div>
              </div>
            )}
            size="tiny"
            basic
            disabled={!this.state.filingButtonDisabled}
            trigger={(
              <button
                className="btn btn--primary file-resolution__button"
                onClick={this.handleModalOpen}
                disabled={this.state.filingButtonDisabled}
              >
                <i className="fas fa-bible" />
                &nbsp;
                File Resolution
              </button>
            )}
          />
        )}
        open={this.state.modalOpen}
        onClose={this.handleModalClose}
      >
        <Modal.Content>
          <form onSubmit={this.handleFormSubmit}>
            <div className="modal__header">
              File Resolution:
              &nbsp;
              {this.props.resolution.titleWithPrefixedNumber}
            </div>
            <div className="modal__content">
              {
                this.props.showFileResolutionsModalErrors
                && <ErrorAlert message={this.props.errorMessage} />
              }

              {
                this.props.showFileResolutionsModalNotice
                && <WarningAlert messages={this.props.noticeMessages} />
              }
              <p>
                Are you sure you wish to file the resolution?
              </p>
              {
                this.props.showFileResolutionsModalNotice
                && (
                  <div className="controls-stacked">
                    <label className="control radio radio__yes">
                      <input
                        type="radio"
                        name="confirm_status"
                        value="yes"
                        onChange={this.handleConfirmToggle}
                        checked={this.state.confirmSubmit === 'yes'}
                      />
                      <span className="control-indicator" />
                      Yes
                    </label>
                    <label className="control radio radio__no">
                      <input
                        type="radio"
                        name="confirm_status"
                        value="no"
                        onChange={this.handleConfirmToggle}
                        checked={this.state.confirmSubmit === 'no'}
                      />
                      <span className="control-indicator" />
                      No
                    </label>
                  </div>
                )
              }
            </div>
            <div className="modal__actions">
              <button className="btn btn--default" onClick={this.handleModalClose}>
                Cancel
              </button>
              &nbsp;
              <button className="btn btn--primary file-resolution__modal-submit">
                File Resolution
              </button>
            </div>
          </form>

        </Modal.Content>
      </Modal>
    );
  }
}

FileResolutionsForm.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  resolution: ResolutionProps.isRequired,
  fileResolution: PropTypes.func.isRequired,
  showFileResolutionsModalErrors: PropTypes.bool.isRequired,
  showFileResolutionsModalNotice: PropTypes.bool.isRequired,
  filingRequirementsMet: PropTypes.bool.isRequired,
  clearFileResolutionsModalNotice: PropTypes.func.isRequired,
  closeFileResolutionsModal: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  noticeMessages: PropTypes.instanceOf(Array).isRequired,
};

const mapState = (state) => ({
  resolution: state.filingRequirements.resolution,
  showFileResolutionsModalErrors: state.filingRequirements.showFileResolutionsModalErrors,
  showFileResolutionsModalNotice: state.filingRequirements.showFileResolutionsModalNotice,
  closeFileResolutionsModal: state.filingRequirements.closeFileResolutionsModal,
  filingRequirementsMet: state.filingRequirements.filingRequirementsMet,
  errorMessage: state.filingRequirements.errorMessage,
  noticeMessages: state.filingRequirements.noticeMessages,
});

const mapDispatch = (dispatch) => ({
  fileResolution: (data) =>
    dispatch.filingRequirements.fileResolution(data),
  clearFileResolutionsModalNotice: () =>
    dispatch.filingRequirements.clearFileResolutionsModalNotice(),
});

export default connect(mapState, mapDispatch)(FileResolutionsForm);
