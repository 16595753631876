import React from 'react';
import { Popup } from 'semantic-ui-react';

function InfoPopup(content) {
  return (
    <Popup
      trigger={
        <i className="fas fa-info-circle house-tooltip" />
    }
      content={content}
      on="hover"
      mouseEnterDelay={500}
      size="tiny"
      position="right center"
      wide
    />
  );
}

export default InfoPopup;
