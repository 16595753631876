import React from 'react';
import { connect } from 'react-redux';
import InfoPopup from '../InfoPopup';
import ResolutionProps from '../../types/ResolutionProps';

class ResolutionNumberFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoGenerateNumber: this.props.resolution.autoGenerateNumber,
      resolutionNumber: this.props.resolution.resolutionNumber || '',
    };

    this.handleAutoGenerateNumberChange = this.handleAutoGenerateNumberChange.bind(this);
    this.handleResolutionNumberChange = this.handleResolutionNumberChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.resolution.resolutionType.id !== nextProps.resolution.resolutionType.id) {
      this.setState({ 
        resolutionNumber: nextProps.resolution.resolutionNumber,
        autoGenerateNumber: nextProps.resolution.autoGenerateNumber,
      });
    }
    return true;
  }

  handleAutoGenerateNumberChange(item) {
    this.setState({ autoGenerateNumber: item.target.checked });
  }

  handleResolutionNumberChange(item) {
    this.setState({ resolutionNumber: item.target.value });
  }

  render() {
    const numberFieldClass = this.state.autoGenerateNumber ? 'hide' : 'form-control form-control--small';
    const numberPrefixClass = this.props.resolution.resolutionType.prefix ? 'resolution-number-group' : 'hide';
    const autoGenerateNumberClass = this.props.resolution.id && this.props.resolution.resolutionNumber ? 'hide' : 'space-below--none';
    const infoPopupClass = this.props.resolution.filed ? 'grey-medium h5' : 'hide';

    return (
      <div className="form-group">
        <label>Number</label>
        <div>
          <div className={numberPrefixClass}>
            <span>{this.props.resolution.resolutionType.prefix}</span>
&ensp;
          </div>
          <div className="resolution-number-group">
            <input
              name="resolution[resolution_number]"
              type="number"
              className={numberFieldClass}
              value={this.state.resolutionNumber}
              onChange={this.handleResolutionNumberChange}
              readOnly={this.props.resolution.filed}
            />
          </div>
          <div className="resolution-number-group">&ensp;</div>
          <span className={infoPopupClass}>
            <InfoPopup
              content="Uneditable for a filed resolution."
            />
          </span>
          <div className={autoGenerateNumberClass}>
            <label className="control checkbox">
              <input
                type="checkbox"
                name="resolution[auto_generate]"
                defaultChecked={this.state.autoGenerateNumber}
                onChange={this.handleAutoGenerateNumberChange}
              />
              <span className="control-indicator" />
              Generate automatically (on save)
            </label>
          </div>
        </div>

        <p className="help-block">
          You can also add a resolution number later. 
          We always give a resolution a pre-filing (PF) number automatically
          when you first save it.
          Last resolution was 
          {' '}
          {this.props.resolution.lastResolutionNumber}
          .
        </p>
      </div>
    );
  }
}

const mapState = (state) => ({
  resolution: state.prefileResolutionForm.resolution,
});

ResolutionNumberFields.propTypes = {
  resolution: ResolutionProps.isRequired,
};

export default connect(mapState)(ResolutionNumberFields);
