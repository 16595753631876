import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoPopup from '../InfoPopup';
import FilingRequirementsStatusIndicator from './FilingRequirementsStatusIndicator';
import ResolutionProps from '../../types/ResolutionProps';

class LegislativeTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleLegislativeTypeChange = this.handleLegislativeTypeChange.bind(this);
  }

  handleLegislativeTypeChange(item) {
    this.props.saveResolutionLegislativeType(item.target.value);
  }

  render() {
    return (
      <tr className="align--middle">
        <td className="table--fixed-column text--center">
          <FilingRequirementsStatusIndicator
            status={this.props.resolution.filingRequirementStatusLegislativeType}
          />
        </td>

        <td className="table--fixed-column"><strong>Legislature</strong></td>

        <td>
          <div className="controls-stacked space-below--none">
            <label className="control radio" htmlFor="legislative_type_one_house">
              <input
                id="legislative_type_one_house"
                type="radio"
                value="one_house"
                checked={this.props.resolution.legislativeType === 'one_house'}
                onChange={this.handleLegislativeTypeChange}
              />
              <span className="control-indicator" />
              One House &nbsp;
              <span className="grey-medium h5">
                <InfoPopup
                  content="Procedural, courtesy"
                />
              </span>
            </label>
            <label className="control radio" htmlFor="legislative_type_two_house">
              <input
                id="legislative_type_two_house"
                type="radio"
                value="two_house"
                checked={this.props.resolution.legislativeType === 'two_house'}
                onChange={this.handleLegislativeTypeChange}
              />
              <span className="control-indicator" />
              Two House &nbsp;
              <span className="grey-medium h5">
                <InfoPopup
                  content="Requires confirmation"
                />
              </span>
            </label>
          </div>
        </td>
      </tr>
    );
  }
}

const mapState = (state) => ({
  resolution: state.filingRequirements.resolution,
});

const mapDispatch = ({ filingRequirements: { saveResolutionLegislativeType } }) => ({
  saveResolutionLegislativeType: (legislativeType) =>
    saveResolutionLegislativeType(legislativeType),
});

LegislativeTypeForm.propTypes = {
  resolution: ResolutionProps.isRequired,
  saveResolutionLegislativeType: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(LegislativeTypeForm);
