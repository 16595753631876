import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import AuthenticationsForm from './AuthenticationsForm';
import store from '../../stores/configureStore';

// Store is only accessible on the page in which this is mounted.
window.store = store;

function AuthenticationsContainer(props) {
  return (
    <Provider store={store}>
      <AuthenticationsForm accessToken={props.accessToken} />
    </Provider>
  );
}

AuthenticationsContainer.propTypes = {
  accessToken: PropTypes.number.isRequired,
};

export default AuthenticationsContainer;
