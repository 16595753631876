const AccessTokensModel = {
  state: {
    accessToken: null,
  },

  reducers: {
    setAccessToken(state, accessToken) {
      return {
        ...state,
        accessToken
      };
    },
  },

  effects: (dispatch) => ({
    async hydrateToken(accessToken) {
      dispatch.accessTokensModel.setAccessToken(accessToken);
    }
  })
};

export default AccessTokensModel;
