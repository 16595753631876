import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AuthenticationsForm extends React.Component {
  componentDidMount() {
    this.props.hydrateToken(this.props.accessToken);
  }

  render() {
    return (
      <div />
    );
  }
}

const mapDispatch = (dispatch) => ({
  hydrateToken: (token) => dispatch.accessTokensModel.hydrateToken(token),
});

AuthenticationsForm.propTypes = {
  accessToken: PropTypes.number.isRequired,
  hydrateToken: PropTypes.func.isRequired
};

export default connect(null, mapDispatch)(AuthenticationsForm);
