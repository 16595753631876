/* eslint-env jquery */
/* eslint-disable no-undef */

export default function restoreBaseVersionText() {
  $('#restore-text-button').on('click', () => {
    const editorId = $('#editor_id').text();
    const baseVersionText = $('#base-version-text').text();

    CKEDITOR.instances[editorId].setData(baseVersionText);
    });
}
