import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';

class SupportersField extends React.Component {
  constructor(props) {
    super(props);

    this.handleSupportersChange = this.handleSupportersChange.bind(this);
    this.handleSupportersInputChange = this.handleSupportersInputChange.bind(this);
  }

  handleSupportersChange(item) {
    this.props.setResolutionSupporters(item || []);
  }

  handleSupportersInputChange(item) {
    const endorser = { value: item, label: item };
    this.props.setSupporters([endorser]);
  }

  render() {
    const selectedValues = this.props.resolution.supporters;

    return (
      <div className="form-group">
        <label>Supported by</label>
        <Select
          options={this.props.supporters}
          value={selectedValues}
          isMulti
          placeholder="Please select..."
          onInputChange={this.handleSupportersInputChange}
          onChange={this.handleSupportersChange}
          theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
              text: 'orangered',
              primary25: '#ddd',
              primary: '#3e8ede',
            },
          })}
        />

        <select 
          className="hide" 
          name="resolution[supporters][]" 
          multiple
          readOnly
          value={this.props.resolution.supporters.map((supporter) => (supporter.value))}
        >
          {
            this.props.resolution.supporters.map((supporter) => 
              (<option value={supporter.value} key={supporter.value} label={supporter.label} />))
          }
        </select>
      </div>  
    );
  }
}

const mapState = (state) => ({
  resolution: state.prefileResolutionForm.resolution,
  supporters: state.prefileResolutionForm.supporters,
});

const mapDispatch = (dispatch) => ({
  setResolutionSupporters: (supporters) => 
    dispatch.prefileResolutionForm.setResolutionSupporters(supporters),
  setSupporters: (supporters) => dispatch.prefileResolutionForm.setSupporters(supporters),
});

SupportersField.propTypes = {
  resolution: ResolutionProps.isRequired,
  supporters: PropTypes.instanceOf(Array).isRequired,
  setResolutionSupporters: PropTypes.func.isRequired,
  setSupporters: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(SupportersField);
