import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResolutionProps from '../../types/ResolutionProps';
import ErrorAlert from '../ErrorAlert';
import ResolutionTypeField from './ResolutionTypeField';
import ResolutionNumberFields from './ResolutionNumberFields';
import ProposerField from './ProposerField';
import SubmitterFields from './SubmitterFields';
import EndorsersField from './EndorsersField';
import SupportersField from './SupportersField';

class PrefileResolutionFormFields extends React.Component {
  componentDidMount() {
    this.props.hydrateResolutionForm(this.props.resolution);
    this.props.setProposerBaseUrl(this.props.proposerBaseUrl);
  }

  render() {
    return ( 
      <div>
        { this.props.showErrors && <ErrorAlert message={this.props.errorMessage} /> }
        <ResolutionTypeField />
        <ResolutionNumberFields />
        <ProposerField />
        <label>Submitter</label>
        <SubmitterFields />
        <EndorsersField />
        <SupportersField />
      </div>
    );
  }
}
 
const mapState = (state) => ({
  errorMessage: state.prefileResolutionForm.errorMessage,
  showErrors: state.prefileResolutionForm.showErrors,
});

const mapDispatch = (dispatch) => ({
  hydrateResolutionForm: (resolution) => 
    dispatch.prefileResolutionForm.hydrateResolutionForm(resolution),
  setProposerBaseUrl: (baseUrl) => dispatch.prefileResolutionForm.setProposerBaseUrl(baseUrl),
});

PrefileResolutionFormFields.propTypes = {
  resolution: ResolutionProps.isRequired,
  proposerBaseUrl: PropTypes.string.isRequired,
  hydrateResolutionForm: PropTypes.func.isRequired,
  setProposerBaseUrl: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

export default connect(mapState, mapDispatch)(PrefileResolutionFormFields);
